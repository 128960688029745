import { useCallback, useEffect, useState } from "react";
import { useWalletClient } from "wagmi";
import { makeContractAPIs } from "../contract";
import { type ChainId } from "../contract";
import { useInterval } from "@mantine/hooks";
import { type MundumData, fetchMundumData } from "./fetchMundumData";

const chainId: ChainId = 80002;

type APIs = Awaited<ReturnType<typeof makeContractAPIs>>;
export type WalletData = {
  mundumData: MundumData | null;

  contractAPIs: null | APIs;
  chain: ChainId;
  focusedContract: string | null;
  setFocusedContract: (newValue: string | null) => void;

  refresh: () => void;
};

export const useWallet = (): WalletData => {
  const [APIs, setAPIs] = useState<null | APIs>(null);
  const [focusedContract, setFocusedContract] = useState<string | null>(null);
  const [mundumData, setMundumData] = useState<MundumData | null>(null);

  const { data: walletClient } = useWalletClient({ chainId: chainId });
  const [chain, setChain] = useState<null | number>(null);

  const refreshData = useCallback((): void => {
    if (walletClient === undefined || walletClient === null) {
      setFocusedContract(null);
      return;
    }
    const currentChain = walletClient.chain.id;

    if (APIs === null || chain !== currentChain) {
      setChain(currentChain);
      setFocusedContract(null);

      const apiBuilding = makeContractAPIs(walletClient);
      apiBuilding.then((contractAPIs) => setAPIs(contractAPIs));
      apiBuilding.catch((error): void => {
        console.error("bubbled into first");
        console.error(error);
      });

      return;
    }

    if (focusedContract === null) {
      return;
    }

    const contract = APIs[focusedContract];

    const tryFetching = (): void => {
      const mundumData = fetchMundumData(contract);
      mundumData.then(setMundumData);
      mundumData.catch((error: Error): void => {
        console.log("error while fetching mundum data");
        setFocusedContract(null);
        setMundumData(null);

        const message = error.message;
        if (message.includes('event="changed", code=NETWORK_ERROR')) {
          console.log("network error, aborting re-fetch sequence");
          return;
        }

        tryFetching();
      });
    };
    tryFetching();
  }, [APIs, focusedContract, walletClient, chain, setChain]);

  const interval = useInterval((): void => refreshData(), 10000);
  useEffect(
    () => {
      interval.start();
      refreshData();
      return interval.stop;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshData]
  );

  return {
    mundumData,

    setFocusedContract,
    chain: (walletClient?.chain.id as ChainId) || chainId,
    focusedContract,
    contractAPIs: APIs,

    refresh: refreshData,
  };
};
