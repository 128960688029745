import { connect } from "./connect";
import { createStakingAPI, type StakingAPI } from "./createStakingAPI";
import { type WalletClient } from "wagmi";

declare global {
  interface Window {
    ethereum?: any;
  }
}

export const makeContractAPIs = async (
  walletClient: WalletClient,
): Promise<Record<string, StakingAPI>> => {
  const { contracts, signerAddress } = await connect(walletClient);
  const { mundum, staking } = contracts;

  const stakingAPIs: Record<string, StakingAPI> = {};

  for (const [address, contract] of Object.entries(staking)) {
    stakingAPIs[address] = createStakingAPI(
      mundum,
      signerAddress,
      contract,
      address,
    );
  }

  return stakingAPIs;
};
