import { Box, Space, Stack } from "@mantine/core";
import React from "react";
import { PoolsOverview } from "./PoolsOverview";
import { StakeCard } from "./StakeCard";
import { useApplication } from "../../Application";
import { FallbackCTA } from "./FallbackCTA";
import { useSectionSize } from "../useSectionSize";

type Props = {
  focusedContract: string;
}
export const Coin: React.FC<Props> = ({
  focusedContract,
}) => {
  const { mundumData } = useApplication();
  const sizing = useSectionSize();



  return (
    <Box style={sizing}>
      <Stack>
        {mundumData === null
          ? <FallbackCTA/>
          : (
            <>
              <PoolsOverview data={mundumData}/>
              <Space h="md"/>
              <StakeCard focusedContract={focusedContract}/>
            </>
          )
        }
      </Stack>
    </Box>
  );
};
