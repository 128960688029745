import { displayPreciseMundum } from "../implementationDetails/displayPreciseMundum";
import { type MundumData } from "./fetchMundumData";

export const rewardBreakdown = (
  reward: bigint | null,
): Pick<MundumData, 'personalReward' | 'amountGoingToCharity'> => {
  if (reward === null) {
    return {
      amountGoingToCharity: displayPreciseMundum(null),
      personalReward: displayPreciseMundum(null),
    };
  }

  const charity = reward / 10n;
  const personal = reward - charity;

  return {
    amountGoingToCharity: displayPreciseMundum(charity),
    personalReward: displayPreciseMundum(personal),
  };
};
