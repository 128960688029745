import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { type PropsWithChildren, useEffect, useState } from "react";
import { polygon, polygonMumbai } from "wagmi/chains";
import { defineChain } from "viem";

const chains = [
  polygon,
  polygonMumbai,
  defineChain({
    id: 80_002,
    name: "Polygon Amoy",
    network: "maticamoy",
    nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
    rpcUrls: {
      default: {
        http: ["https://rpc-amoy.polygon.technology"],
      },
      public: {
        http: ["https://rpc-amoy.polygon.technology"],
      },
    },
    blockExplorers: {
      default: {
        name: "PolygonScan",
        url: "https://amoy.polygonscan.com/",
      },
    },
    contracts: {
      multicall3: {
        address: "0xca11bde05977b3631167028862be2a173976ca11",
        blockCreated: 3127388,
      },
    },
    testnet: true,
  }),
];

// 1. Get projectID at https://cloud.walletconnect.com
const projectId = process.env.REACT_APP_WC_PROJECT_ID || "";

const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
});

createWeb3Modal({ wagmiConfig, projectId });

export const WalletConfig: React.FC<PropsWithChildren> = ({ children }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => setReady(true), []);

  return (
    <>
      {ready ? (
        <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>
      ) : null}
    </>
  );
};
