import {
  AppShell,
  Badge,
  Burger,
  Flex,
  Grid,
  Image,
  Stack,
} from "@mantine/core";
import React, { useEffect, useMemo } from "react";
import mundum_logo from "../mundum.svg";
import { LanguagePicker } from "./LanguagePicker";
import { HeaderWallet } from "./HeaderWallet";
import { munColors } from "../Misc/munColors";
import { useApplication } from "../Application";
import { useDisclosure } from "@mantine/hooks";
import { WalletConnectButton } from "../Misc/WalletConnectButton";

type Props = {
  height: number;
};

export const Header: React.FC<Props> = ({ height }) => {
  const { width } = useApplication();
  const [opened, { toggle, close }] = useDisclosure();
  useEffect((): void => {
    if (width !== "sm") {
      return;
    }
    close();
  }, [width, close]);

  const content = useMemo((): JSX.Element => {
    const [left, right] = width === "md" ? [4, 8] : [6, 6];

    if (width !== "sm") {
      return (
        <Grid align="center">
          <Grid.Col span={left}>
            <Flex align="center" justify="center" gap={10}>
              <Image src={mundum_logo} fit="contain" h={height} />
            </Flex>
          </Grid.Col>
          <Grid.Col span={right}>
            <Flex justify="center" gap={10}>
              <WalletConnectButton />
              <HeaderWallet />
              <LanguagePicker style={{ width: "120px" }} />
            </Flex>
          </Grid.Col>
        </Grid>
      );
    }

    return (
      <Flex align="center" gap={5} style={{ paddingLeft: "10px" }}>
        <Burger opened={opened} onClick={toggle} size={36} />
        <Image src={mundum_logo} fit="contain" h={height} />
      </Flex>
    );
  }, [width, height, opened, toggle]);

  return (
    <>
      <AppShell.Header style={{ backgroundColor: munColors.bgDark }}>
        {content}
      </AppShell.Header>
      {opened && (
        <AppShell.Navbar style={{ background: munColors.bgGradientCSS }} p={10}>
          <Stack gap={5} justify="stretch">
            <WalletConnectButton />
            <HeaderWallet />
            <LanguagePicker />
          </Stack>
        </AppShell.Navbar>
      )}
    </>
  );
};
