import { polygon, polygonMumbai } from "wagmi/chains";
import { mundumAbi, stakingAbi } from "./abi";

export type ChainId =
  | (typeof polygon)["id"]
  | (typeof polygonMumbai)["id"]
  | 80002;

type MundumContract = {
  address: string;
  abi: string[];
};
export const mundumContract: Record<ChainId, MundumContract> = {
  137: {
    address: "0x9eed310a92a44ebc9fd144d98f1772110e74b930",
    abi: mundumAbi,
  },
  80001: {
    address: "0x8c6Df6381aFc9821977a17Fc7846e229B649dE04",
    abi: mundumAbi,
  },
  80002: {
    address: "0x1AF97622723fEd5621fc764d0871998d9f2BAE14",
    abi: mundumAbi,
  },
};

type StakingContract = {
  abi: string[];
  address: string;
  locked: boolean;
  durationYears: number;
  rewardTotal: number;
};
const makeContract = (
  address: string,
  locked: boolean,
  durationYears: number,
  rewardTotal: number
): StakingContract => {
  return {
    abi: stakingAbi,
    address,
    durationYears,
    locked,
    rewardTotal,
  };
};
export const contracts: Record<ChainId, StakingContract[]> = {
  137: [
    makeContract(
      "0x796042777d4F753BbCBB67F8a60de19949D752f9",
      true,
      5,
      120_000_000
    ),
    makeContract(
      "0x6e7897842d40a486f0DEFF7F5a9F059D2286f274",
      true,
      3,
      54_000_000
    ),
    makeContract(
      "0x43870C4676de7b0f7897ff0132bBc06E3Ec34A63",
      true,
      1,
      12_000_000
    ),
    makeContract(
      "0xBaCb4CFf9DA7501F78E4B5cF4c77c36CA2A89540",
      false,
      1,
      6_000_000
    ),
    makeContract("0x9D8e23ACC583285986f5569AC05781AdC690402c", true, -1, 0),
  ],
  80002: [
    makeContract(
      "0x453A23509F10B3d8E2C5FC71bF08b9107555D31a",
      true,
      5,
      120_000_000
    ),
    makeContract(
      "0x5be3Eac96B404406256e5411afE0D0ecC282D57d",
      true,
      3,
      54_000_000
    ),
    makeContract(
      "0x59CFE071164E6a445E61146240e9B4Eeb8eC51E9",
      true,
      1,
      12_000_000
    ),
  ],
  80001: [
    makeContract("0x7dfce136dDaCe81674705f2B225e3d33255De490", false, -1, 1),
    makeContract("0x4fd81B4B5C2BE2322F5A63e9656F109456A6E3Ed", true, 1, 1),
  ],
};
